
import { Component, Vue } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { MenuSql, MenuConfigInfo } from '../../types/menu'

@Component
export default class MenuConfig extends Vue {
  private info = {
    isConfig: '1',
    menuTable: [],
    menuDev: [],
    menuTemplate: []
  }

  private sqlInfo= {
    menuSqlUp: '',
    menuSqlTable: '',
    menuSqlVal: ''
  }

  private rules = {}

  private tableList = []
  private deviceTypeList = []
  private templateList = []
  private tagList: Array<MenuSql> = []
  private templateShow = true

  private checkedDeviceList: Array<MenuSql> = [] // 已选中设备类型列表
  private checkedTemplateList: Array<MenuSql> = [] // 已选中的消息模板列表
  private manualList: Array<MenuSql> = [] // 自定义sql列表
  private initSqlList: Array<MenuSql> = [] // 初始显示sql数据

  private submitShow = false
  private titleList: any = {
    1: '短信',
    2: '邮件',
    3: '长连接/定时拉取',
    4: '订阅消息',
    5: '客服消息',
    6: 'app推送'
  }

  private props = {
    multiple: true,
    checkStrictly: true,
    value: 'menuSqlVal',
    label: 'templateTitle',
    emitPath: false,
    lazy: true,
    lazyLoad: this.lazyLoad
  }

  created () {
    this.getTableList()
    this.getDeviceTypeList()
    this.getTemplateList()
    this.getData()
  }

  // 动态加载 处理子节点数据
  lazyLoad (node: any, resolve: Function) {
    const arr: Array<object> = []
    if (node.data && node.data.channelList) {
      node.data.channelList.map((item: any) => {
        arr.push({
          menuSqlTable: item.menuSqlTable,
          channel: item.channel,
          id: item.id,
          templateId: item.templateId,
          menuSqlVal: item.menuSqlVal,
          menuSqlUp: item.menuSqlUp,
          templateTitle: this.titleList[item.channel],
          leaf: !item.channelList
        })
      })
    }
    resolve(arr)
  }

  getData () {
    this.$axios.get(this.$apis.menu.selectJurisdiction, {
      menuId: this.$route.params.id
    }).then(res => {
      res.menuSqlList.map((item: MenuSql) => {
        this.initSqlList.push({
          menuSqlUp: item.menuSqlUp,
          menuSqlTable: item.menuSqlTable,
          menuSqlVal: item.menuSqlVal,
          type: 'init'
        })
      })
      this.tagList = [...this.initSqlList]
      this.info.menuTable = res.menuTableList.map((item: {tableName: string}) => item.tableName)
    })
  }

  getTableList () {
    this.$axios.get(this.$apis.menu.selectTableList).then(res => {
      this.tableList = res || []
    })
  }

  getDeviceTypeList () {
    this.$axios.get(this.$apis.menu.selectDeviceType).then(res => {
      this.deviceTypeList = res || []
    })
  }

  getTemplateList () {
    this.$axios.get(this.$apis.menu.selectMsgTemplate).then(res => {
      this.templateList = res || []
    })
  }

  // 设备类型变化
  changeDev () {
    this.$nextTick(() => {
      this.checkedDeviceList = []
      this.info.menuDev.map((menuSqlVal: string) => {
        const obj: any = this.deviceTypeList.find((item: {menuSqlVal: string}) => item.menuSqlVal === menuSqlVal)
        this.checkedDeviceList.push({
          menuSqlUp: obj.menuSqlUp,
          menuSqlTable: obj.menuSqlTable,
          menuSqlVal: obj.menuSqlVal,
          type: 'dev'
        })
      })
      this.tagList = [...this.checkedTemplateList, ...this.checkedDeviceList, ...this.manualList, ...this.initSqlList]
    })
  }

  // 消息模板变化
  changeTemplate () {
    console.log((this.$refs.cascader as any).getCheckedNodes())
    this.$nextTick(() => {
      this.checkedTemplateList = [];
      (this.$refs.cascader as any).getCheckedNodes().map((item: any) => {
        this.checkedTemplateList.push({
          menuSqlUp: item.data.menuSqlUp,
          menuSqlTable: item.data.menuSqlTable,
          menuSqlVal: item.data.menuSqlVal,
          type: 'template'
        })
      })
      this.tagList = [...this.checkedTemplateList, ...this.checkedDeviceList, ...this.manualList, ...this.initSqlList]
    })
  }

  // 移除tag
  removeTag (item: MenuSql, index: number) {
    if (item.type === 'dev') {
      const removeIndex = (this.info.menuDev as string[]).indexOf(item.menuSqlVal as string)
      this.info.menuDev.splice(removeIndex, 1)
      this.checkedDeviceList.splice(removeIndex, 1)
    }

    if (item.type === 'template') {
      const arr = JSON.parse(JSON.stringify(this.info.menuTemplate))
      arr.map((tag: string, index: number) => {
        if (tag === item.menuSqlVal) {
          arr.splice(index, 1)
          this.checkedTemplateList.splice(index, 1)
        }
      })
      this.info.menuTemplate = arr
    }

    if (item.type === 'manua') {
      const removeIndex = this.manualList.findIndex((tag) => {
        return tag.menuSqlVal === item.menuSqlVal
      })
      this.manualList.splice(removeIndex, 1)
    }

    if (item.type === 'init') {
      this.initSqlList.map((initItem: MenuSql, index: number) => {
        initItem.menuSqlVal === item.menuSqlVal && this.initSqlList.splice(index, 1)
      })
    }
    console.log(index)
    this.tagList.splice(index, 1)
  }

  // 自定义sql添加
  addSql () {
    this.manualList.push({
      menuSqlUp: this.sqlInfo.menuSqlUp,
      menuSqlTable: this.sqlInfo.menuSqlTable,
      menuSqlVal: this.sqlInfo.menuSqlVal,
      type: 'manua'
    })
    this.tagList = [...this.checkedTemplateList, ...this.checkedDeviceList, ...this.manualList, ...this.initSqlList]
    this.sqlInfo = {
      menuSqlUp: '',
      menuSqlTable: '',
      menuSqlVal: ''
    }
  }

  onSubmit () {
    (this.$refs.info as ElForm).validate((valid) => {
      if (valid) {
        this.submitShow = true
        const info: MenuConfigInfo = {
          menuId: this.$route.params.id,
          menuTableList: [],
          menuSqlList: []
        }
        this.info.menuTable.map((item: string) => {
          info.menuTableList.push({
            tableName: item
          })
        })
        info.menuSqlList = this.menuSqlListFilter()
        this.$axios.post(this.$apis.menu.configAdd, info).then(() => {
          this.$message({
            message: '保存成功',
            type: 'success'
          })
          this.$router.push({ name: 'menuSetting' })
        }).finally(() => {
          this.submitShow = false
        })
      }
    })
  }

  // 保存时sql列表处理
  menuSqlListFilter () {
    const templateList: Array<MenuSql> = []
    const devList: Array<MenuSql> = []
    const manualList: Array<MenuSql> = []
    const initList: Array<MenuSql> = [];

    // 模板sql去重添加
    (this.$refs.cascader as any).getCheckedNodes().map((item: any) => {
      templateList.push({
        menuSqlUp: item.data.menuSqlUp,
        menuSqlTable: item.data.menuSqlTable,
        menuSqlVal: item.data.menuSqlVal
      })
      if (item.parent) {
        templateList.push({
          menuSqlUp: item.parent.data.menuSqlUp,
          menuSqlTable: item.parent.data.menuSqlTable,
          menuSqlVal: item.parent.data.menuSqlVal
        })
      }
    })

    // 设备sql添加
    this.info.menuDev.map((menuSqlVal: string) => {
      const obj: any = this.deviceTypeList.find((item: {menuSqlVal: string}) => item.menuSqlVal === menuSqlVal)
      devList.push({
        menuSqlUp: obj.menuSqlUp,
        menuSqlTable: obj.menuSqlTable,
        menuSqlVal: obj.menuSqlVal
      })
    })

    // 自定义sql添加
    this.manualList.map(obj => {
      manualList.push({
        menuSqlUp: obj.menuSqlUp,
        menuSqlTable: obj.menuSqlTable,
        menuSqlVal: obj.menuSqlVal
      })
    })

    // 初始sql添加
    this.initSqlList.map(obj => {
      initList.push({
        menuSqlUp: obj.menuSqlUp,
        menuSqlTable: obj.menuSqlTable,
        menuSqlVal: obj.menuSqlVal
      })
    })
    const allArr = [...templateList, ...devList, ...manualList, ...initList]
    const res = new Map()
    return allArr.filter((a) => !res.has(a.menuSqlVal) && res.set(a.menuSqlVal, 1))
  }
}
